/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

//@import '../../@core/scss/base/bootstrap-extended/_variables.scss';
//@import '../../@core/scss/base/core/colors/palette-variables.scss';
//@import '../../@core/scss/base/components/_variables.scss';
@import './variables/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints';
.color{
    &-red {
        color: $red;
    }
    &-orange {
        color: $orange;
    }
    &-green {
        color: $success;
    }
}

.btn-outline{
    &-upgrade {
      $color_value: rgba(214, 51, 132, 1);
      color:$color_value;
      border-color: $color_value;

      &:hover:not(.disabled):not(:disabled) {
        background-color: rgba($color: $color_value, $alpha: 0.04);
        color: $color_value;
      }
      &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
      }
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):focus {
        background-color: rgba($color: $color_value, $alpha: 0.2);
        color: $color_value;
      }
  }
}

.badge{
    &-color-professional {
        background: #0247511F;
        color: #024751;
    }

    &-color-core {
        background: #6CB7411F;
        color: #6CB741;
    }

    
}

@mixin circle-shared($main-color) {
    $outer-color: rgba($main-color, 0.31);    
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: $main-color;
    box-shadow: 0px 0px 0px 2px $outer-color;
}

.circle{
    &-in-progress {
        @include circle-shared($blue);        
    }
    &-active {
        @include circle-shared($lightgreen);
    }
    &-in-review {
        @include circle-shared($orange);
    }
    &-disabled {
        @include circle-shared($secondary);
    }
    &-declined {
        @include circle-shared($red);
    }
}

.landing-color {
    &-grey { background-color: $landing-grey }
    &-green { background-color: $landing-green }
    &-yellow { background-color: $landing-yellow; }
    &-red { background-color: $landing-red }
    &-blue {background-color: $landing-blue }
    &-wetu-green {background-color: #F1F8EC;}
    &-light-grey {background: rgba(186, 191, 199, 0.12)}
}

.landing-border {
    border-color: #E5E5E5 !important;
}

.background-color-light-grey-blue {
    background-color: $light-gray-blue;
}
.padding-right-5px {
   padding-right: 5px !important; 
}

.bold {
	font-weight: bold;
}

.font-weight {
    &-500 { font-weight: 500; }
    &-600 { font-weight: 600; }
}

.error {
	@extend .color-red;
}

.error-text {
	@extend .color-red;
    font-size: smaller;
}

.font-size {
    &-10 { font-size: 10px }
    &-11 { font-size: 11px !important;  }
    &-12 { font-size: 12px; }
    &-13 { font-size: 13px; }
    &-14 { font-size: 14px; }
    &-15 { font-size: 15px; }
    &-16 { font-size: 16px; }
    &-18 { font-size: 18px; }
    &-smaller { font-size: smaller; }
}

.line-height {
    &-18 { line-height: 18px; }
    &-36 { line-height: 36px; }
}

.font-size-1-rem {
    font-size: 1rem;
}

.font-size {
    @media (max-width: 576px) {
        &-md {
            &-1-3-rem{
                font-size: 1.3rem;    
            }
        }
    }
    @media (max-width: 375px) {
        &-sm {
            &-1-0-rem{
                font-size: 1rem;    
            }
        }
    }
    &-1-rem{
        font-size: 1rem;
    }
}

.action-row-selected {
    background-color: rgb(238, 238, 238);
}

.font-size-1-4rem {
    font-size: 1.4rem;
}

.cursor-pointer{
    cursor: pointer;   
}

.cursor-default{
    cursor: default;
}

.h-1-4-rem {
    height: 1.4rem;
}

.disabled-button {
    opacity: 1 !important; // overriding the disabled opacity
    color: #B9B9C3;
    
    &:hover {
        opacity: 1 !important; // overriding the disabled opacity
        color: #B9B9C3;
    }
}

.opacity-0-5 {
    opacity: 0.5;
}

.table-header {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    color: #B9B9C3;
}

.list-remove-bullet {
    list-style: none;
}

.p {
    &-0-1 { padding: 0.1rem; }
    &-0-2 { padding: 0.2rem; }
    &-0-3 { padding: 0.3rem; }
    &-0-4 { padding: 0.4rem; }
    &-0-5 { padding: 0.5rem; }
    &-0-6 { padding: 0.6rem; }
    &-0-7 { padding: 0.7rem; }
    &-0-8 { padding: 0.8rem; }
    &-0-9 { padding: 0.9rem; }
    &-2-5 { padding: 2.5rem; }
    &-2-0 { padding: 2rem !important;}
}

.pt {
    &-n-0-3 { padding-top: -0.3rem; }
    &-0-1 { padding-top: 0.1rem; }
    &-0-2 { padding-top: 0.2rem; }
    &-0-3 { padding-top: 0.3rem; }
    &-0-4 { padding-top: 0.4rem; }
    &-0-5 { padding-top: 0.5rem; }
    &-0-6 { padding-top: 0.6rem; }
    &-0-7 { padding-top: 0.7rem; }
    &-0-8 { padding-top: 0.8rem; }
    &-0-9 { padding-top: 0.9rem; }
    &-1-1 { padding-top: 1.1rem; }
    &-1-2 { padding-top: 1.2rem; }
    &-1-3 { padding-top: 1.3rem; }
    &-1-4 { padding-top: 1.4rem; }
    &-1-5 { padding-top: 1.5rem; }
    &-1-6 { padding-top: 1.6rem; }
    &-1-7 { padding-top: 1.7rem; }
    &-1-8 { padding-top: 1.8rem; }
    &-1-9 { padding-top: 1.9rem; }
    &-2 { padding-top: 2rem !important; }
    &-2-1 { padding-top: 2.1rem; }
    &-2-2 { padding-top: 2.2rem; }
    &-2-3 { padding-top: 2.3rem; }
    &-2-4 { padding-top: 2.4rem; }
    &-2-5 { padding-top: 2.5rem; }
}

.pr {
    &-0-1 { padding-right: 0.1rem; }
    &-0-2 { padding-right: 0.2rem; }
    &-0-3 { padding-right: 0.3rem; }
    &-0-4 { padding-right: 0.4rem; }
    &-0-5 { padding-right: 0.5rem !important; }
    &-0-6 { padding-right: 0.6rem; }
    &-0-7 { padding-right: 0.7rem; }
    &-0-8 { padding-right: 0.8rem; }
    &-0-9 { padding-right: 0.9rem; }
    &-1-1 { padding-right: 1.1rem; }
    &-1-2 { padding-right: 1.2rem; }
    &-1-3 { padding-right: 1.3rem; }
    &-1-4 { padding-right: 1.4rem; }
    &-1-5 { padding-right: 1.5rem !important; }
    &-1-6 { padding-right: 1.6rem; }
    &-1-7 { padding-right: 1.7rem; }
    &-1-8 { padding-right: 1.8rem; }
    &-1-9 { padding-right: 1.9rem; }
    &-2 { padding-right: 2rem !important; }
    &-2-1 { padding-right: 2.1rem; }
    &-2-2 { padding-right: 2.2rem; }
    &-2-3 { padding-right: 2.3rem; }
    &-2-4 { padding-right: 2.4rem; }
    &-2-5 { padding-right: 2.5rem; }
}

.pb {
    &-0-1 { padding-bottom: 0.1rem; }
    &-0-2 { padding-bottom: 0.2rem; }
    &-0-3 { padding-bottom: 0.3rem; }
    &-0-4 { padding-bottom: 0.4rem; }
    &-0-5 { padding-bottom: 0.5rem; }
    &-0-6 { padding-bottom: 0.6rem; }
    &-0-7 { padding-bottom: 0.7rem; }
    &-0-8 { padding-bottom: 0.8rem; }
    &-0-9 { padding-bottom: 0.9rem; }
    &-1-1 { padding-bottom: 1.1rem; }
    &-1-2 { padding-bottom: 1.2rem; }
    &-1-3 { padding-bottom: 1.3rem; }
    &-1-4 { padding-bottom: 1.4rem; }
    &-1-5 { padding-bottom: 1.5rem; }
    &-1-6 { padding-bottom: 1.6rem; }
    &-1-7 { padding-bottom: 1.7rem; }
    &-1-8 { padding-bottom: 1.8rem; }
    &-1-9 { padding-bottom: 1.9rem; }
    &-2 { padding-bottom: 2rem !important; }
    &-2-1 { padding-bottom: 2.1rem; }
    &-2-2 { padding-bottom: 2.2rem; }
    &-2-3 { padding-bottom: 2.3rem; }
    &-2-4 { padding-bottom: 2.4rem; }
    &-2-5 { padding-bottom: 2.5rem !important; }
}

.pl {
    &-0-1 { padding-left: 0.1rem }
    &-0-2 { padding-left: 0.2rem !important; }
    &-0-3 { padding-left: 0.3rem }
    &-0-4 { padding-left: 0.4rem }
    &-0-5 { padding-left: 0.5rem !important; }
    &-0-6 { padding-left: 0.6rem }
    &-0-7 { padding-left: 0.7rem }
    &-0-8 { padding-left: 0.8rem }
    &-0-9 { padding-left: 0.9rem }
    &-1-0 { padding-left: 1rem !important; }
    &-1-1 { padding-left: 1.1rem; }
    &-1-2 { padding-left: 1.2rem; }
    &-1-3 { padding-left: 1.3rem; }
    &-1-4 { padding-left: 1.4rem; }
    &-1-5 { padding-left: 1.5rem !important; }
    &-1-6 { padding-left: 1.6rem; }
    &-1-7 { padding-left: 1.7rem; }
    &-1-8 { padding-left: 1.8rem; }
    &-1-9 { padding-left: 1.9rem; }
    &-2 { padding-left: 2rem !important; }
    &-2-1 { padding-left: 2.1rem; }
    &-2-2 { padding-left: 2.2rem; }
    &-2-3 { padding-left: 2.3rem; }
    &-2-4 { padding-left: 2.4rem; }
    &-2-5 { padding-left: 2.5rem; }
}

.mt-px {
    &-n-10 { margin-top: -10px; }
    &-n-21 { margin-top: -21px; }
    &-23 { margin-top: 23px !important; }   //important needed to override spacing.scss.
}

.mt {
    &-n-0-3 { margin-top: -0.3rem; }
    &-n-0-25 {margin-top: -0.25rem !important; }
    &-n-0-125 { margin-top: -0.125rem; }
    &-0-1 { margin-top: 0.1rem; }
    &-0-2 { margin-top: 0.2rem; }
    &-0-3 { margin-top: 0.3rem; }
    &-0-4 { margin-top: 0.4rem; }
    &-0-5 { margin-top: 0.5rem; }
    &-0-6 { margin-top: 0.6rem; }
    &-0-625 { margin-top: 0.625rem; }   //10px
    &-0-7 { margin-top: 0.7rem; }
    &-0-8 { margin-top: 0.8rem; }
    &-0-9 { margin-top: 0.9rem; }
    &-1-1 { margin-top: 1.1rem; }
    &-1-2 { margin-top: 1.2rem; }
    &-1-3 { margin-top: 1.3rem; }
    &-1-4 { margin-top: 1.4rem; }
    &-1-5 { margin-top: 1.5rem !important; }
    &-1-6 { margin-top: 1.6rem; }
    &-1-7 { margin-top: 1.7rem; }
    &-1-8 { margin-top: 1.8rem; }
    &-1-9 { margin-top: 1.9rem; }
    &-2-0 { margin-top: 2rem; }
    &-2-1 { margin-top: 2.1rem; }
    &-2-2 { margin-top: 2.2rem; }
    &-2-3 { margin-top: 2.3rem; }
    &-2-4 { margin-top: 2.4rem; }
    &-2-5 { margin-top: 2.5rem; }
    &-5-125 { margin-top: 5.125rem; }
}

.mr {
    &-n-1-0 { margin-right: -1rem; }
    &-n-0-3 { margin-right: -0.3rem; }
    &-0-1 { margin-right: 0.1rem; }
    &-0-2 { margin-right: 0.2rem; }
    &-0-3 { margin-right: 0.3rem; }
    &-0-4 { margin-right: 0.4rem; }
    &-0-5 { margin-right: 0.5rem; }
    &-0-6 { margin-right: 0.6rem; }
    &-0-7 { margin-right: 0.7rem; }
    &-0-8 { margin-right: 0.8rem; }
    &-0-9 { margin-right: 0.9rem; }
    &-1-1 { margin-right: 1.1rem; }
    &-1-2 { margin-right: 1.2rem; }
    &-1-3 { margin-right: 1.3rem; }
    &-1-4 { margin-right: 1.4rem; }
    &-1-5 { margin-right: 1.5rem; }
    &-1-6 { margin-right: 1.6rem; }
    &-1-7 { margin-right: 1.7rem; }
    &-1-8 { margin-right: 1.8rem; }
    &-1-9 { margin-right: 1.9rem; }
    &-2-1 { margin-right: 2.1rem; }
    &-2-2 { margin-right: 2.2rem; }
    &-2-3 { margin-right: 2.3rem; }
    &-2-4 { margin-right: 2.4rem; }
    &-2-5 { margin-right: 2.5rem; }
    &-5-0 { margin-right: 5rem !important; }
}

.mb {
    &-0-1 { margin-bottom: 0.1rem !important; }
    &-0-2 { margin-bottom: 0.2rem !important; }
    &-0-3 { margin-bottom: 0.3rem !important; }
    &-0-375 { margin-bottom: 0.375rem; }    //6px
    &-0-4 { margin-bottom: 0.4rem !important; }
    &-0-5 { margin-bottom: 0.5rem; }
    &-0-6 { margin-bottom: 0.6rem; }
    &-0-7 { margin-bottom: 0.7rem; }
    &-0-8 { margin-bottom: 0.8rem; }
    &-0-9 { margin-bottom: 0.9rem; }
    &-1-1 { margin-bottom: 1.1rem; }
    &-1-2 { margin-bottom: 1.2rem; }
    &-1-3 { margin-bottom: 1.3rem; }
    &-1-4 { margin-bottom: 1.4rem; }
    &-1-5 { margin-bottom: 1.5rem; }
    &-1-6 { margin-bottom: 1.6rem; }
    &-1-7 { margin-bottom: 1.7rem; }
    &-1-8 { margin-bottom: 1.8rem; }
    &-1-9 { margin-bottom: 1.9rem; }
    &-2-0 { margin-bottom: 2rem !important; }
    &-2-1 { margin-bottom: 2.1rem; }
    &-2-2 { margin-bottom: 2.2rem; }
    &-2-3 { margin-bottom: 2.3rem; }
    &-2-4 { margin-bottom: 2.4rem; }
    &-2-5 { margin-bottom: 2.5rem; }
    &-3 { margin-bottom: 3rem !important; }
}

.ml {
    &-n-1-0 { margin-left: -1rem; }
    &-0-1 { margin-left: 0.1rem }
    &-0-2 { margin-left: 0.2rem !important; }
    &-0-3 { margin-left: 0.3rem }
    &-0-4 { margin-left: 0.4rem }
    &-0-5 { margin-left: 0.5rem !important; }
    &-0-6 { margin-left: 0.6rem }
    &-0-7 { margin-left: 0.7rem }
    &-0-8 { margin-left: 0.8rem }
    &-0-9 { margin-left: 0.9rem }
    &-1-1 { margin-left: 1.1rem; }
    &-1-2 { margin-left: 1.2rem; }
    &-1-3 { margin-left: 1.3rem; }
    &-1-4 { margin-left: 1.4rem; }
    &-1-5 { margin-left: 1.5rem; }
    &-1-6 { margin-left: 1.6rem; }
    &-1-7 { margin-left: 1.7rem; }
    &-1-8 { margin-left: 1.8rem; }
    &-1-9 { margin-left: 1.9rem; }
    &-2-1 { margin-left: 2.1rem; }
    &-2-2 { margin-left: 2.2rem; }
    &-2-3 { margin-left: 2.3rem; }
    &-2-4 { margin-left: 2.4rem; }
    &-2-5 { margin-left: 2.5rem; }
    &-3-25 { margin-left: 3.25rem; }
}

.bold {
    font-weight: bold;
}

.cursor {
    &-pointer { cursor: pointer; }
    &-grab { cursor: grab; }
}
.w {
    @media (min-width: 768px) {
        &-md {
            &-75 { width: 75% !important; }
        }
    }
}

.w-px {
    @media (min-width: 768px) {
        &-md {
            &-96 { width: 96px; }
            &-150 { width: 150px !important; }
        }
    }

    &-10 { width: 10px; }
    &-18 { width: 18px; }
    &-20 { width: 20px !important; }
    &-21 { width: 21px; }
    &-22 { width: 22px; }
    &-23 { width: 23px; }
    &-24 { width: 24px; }
    &-25 { width: 25px; }
    &-26 { width: 26px; }
    &-27 { width: 27px; }
    &-28 { width: 28px; }
    &-29 { width: 29px; }
    &-30 { width: 30px; }
    &-31 { width: 31px; }
    &-32 { width: 32px; }
    &-33 { width: 33px; }
    &-34 { width: 34px; }
    &-35 { width: 35px; }
    &-36 { width: 36px; }
    &-37 { width: 37px; }
    &-38 { width: 38px; }
    &-39 { width: 39px; }
    &-40 { width: 40px; }
    &-50 { width: 50px; }
    &-60 { width: 60px; }
    &-70 { width: 70px; }
    &-75 { width: 75px; }
    &-80 { width: 80px; }
    &-90 { width: 90px; }
    &-110 { width: 110px; }
    &-120 { width: 120px; }
    &-150 { width: 150px; }
    &-165 { width: 165px; }
    &-200 { width: 200px; }
    &-230 { width: 230px; }
    &-235 { width: 235px; }
    &-300 { width: 300px; }
    &-350 { width: 340px; }
}

.h-px {
    &-4 { height: 4px; }
    &-15 { height: 15px; }
    &-16 { height: 16px; }
    &-17 { height: 17px; }
    &-18 { height: 18px; }
    &-20 { height: 20px; }
    &-21 { height: 21px; }
    &-22 { height: 22px; }
    &-23 { height: 23px; }
    &-24 { height: 24px; }
    &-25 { height: 25px; }
    &-26 { height: 26px; }
    &-27 { height: 27px; }
    &-28 { height: 28px; }
    &-29 { height: 29px; }
    &-30 { height: 30px; }
    &-31 { height: 31px; }
    &-32 { height: 32px; }
    &-33 { height: 33px; }
    &-34 { height: 34px; }
    &-35 { height: 35px; }
    &-36 { height: 36px; }
    &-37 { height: 37px; }
    &-38 { height: 38px; }
    &-39 { height: 39px; }
    &-40 { height: 40px; }
    &-45 { height: 45px; }
    &-50 { height: 50px; }
    &-54 { height: 54px; }
    &-57 { height: 57px; }
    &-60 { height: 60px; }    
    &-100 { height: 100px; }
    &-150 { height: 150px; }
    &-155 { height: 155px; }
    &-234 { height: 234px; }
    
    @media (min-width:375px) {
        &-sm{
            &-50 { height: 50px; }
        }
    }
    @media (min-width: 768px) {
        &-md {
             &-100 { height: 100px; }
        }
    }
}

.min-width {
    &-1 { min-width: 1rem; }
    &-2 { min-width: 2rem; }
    &-3 { min-width: 3rem; }
    &-px {
        &-0 { min-width: 0 !important; }
        &-50 { min-width: 50px !important; } //Added !important to enforce style on rates grid col (where used)
        &-60 { min-width: 60px; }
        &-70 { min-width: 70px; }
        &-72 { min-width: 72px; }
        &-75 { min-width: 75px; }
        &-80 { min-width: 80px; }
        &-90 { min-width: 90px; }
        &-100 { min-width: 100px; }
        &-110 { min-width: 110px; }
        &-120 { min-width: 120px; }
        &-130 { min-width: 130px; }
        &-140 { min-width: 140px; }
        &-150 { min-width: 150px; }
        &-160 { min-width: 160px; }
        &-170 { min-width: 170px; }
        &-180 { min-width: 180px; }
        &-190 { min-width: 190px; }
        &-200 { min-width: 200px; }
        &-210 { min-width: 210px; }
        &-220 { min-width: 220px; }
        &-230 { min-width: 230px; }
        &-231 { min-width: 231px !important; }
        &-240 { min-width: 240px; }
        &-250 { min-width: 250px; }
        &-260 { min-width: 260px; }
        &-270 { min-width: 270px; }
        &-280 { min-width: 280px; }
        &-285 { min-width: 285px; }
        &-290 { min-width: 290px; }
        &-300 { min-width: 300px; }
        &-340 { min-width: 340px; }        
        &-350 { min-width: 350px; }        
        &-450 { min-width: 450px; } 
        &-1480 { min-width: 1480px; }
    }
    @include media-breakpoint-up(sm) {
        &-sm-px {
            &-100 { min-width: 100px; }
            &-250 { min-width: 250px; }
        }  
    }  
    @include media-breakpoint-up(md) {
        &-md-px {
            &-150 { min-width: 150px; }
            &-250 { min-width: 250px; }
        }    
    }
}

.min-height
{   &-px {
        &-0 { min-height: 0 !important; }
        &-18 { min-height: 18px; }
        &-20 { min-height: 20px; }
        &-22 { min-height: 22px; }
        &-25 { min-height: 25px; }
        &-30 { min-height: 30px; }
        &-38 { min-height: 38px; }
        &-50 { min-height: 50px; }
        &-57 { min-height: 57px; }
        &-100 { min-height: 100px; }
        &-132 { min-height: 132px; }
        &-200 { min-height: 100px; }
        &-400 { min-height: 400px; }
        &-500 { min-height: 500px; }
        &-750 { min-height: 750px; }

    }
    @include media-breakpoint-up(md) {
        &-md-px {
            &-100 { min-height: 100px; }            
        }    
    }
}

.min-height-pc {
    &-100 { min-height: 100%; }
}

.min-width-pc {
    &-100 { min-width: 100%; }
}

.max-height-px {
    &-38 { max-height: 38px; }
    &-50 { max-height: 50px; }
    &-60 { max-height: 60px; }
    &-100 { max-height: 100px; }
    &-200 { max-height: 200px; }
    &-250 { max-height: 250px; }
    &-280 { max-height: 280px !important; }
    &-400 { max-height: 400px; }
    &-500 { max-height: 500px; }
}

.max-height-vh {
    &-50 { max-height: 50vh; }
}

.height {
    &-20 { height: 20px; }
}

.h-pc {
    &-100 { height: 100%; }
}

.w-pc {
    &-30 { width: 30%; }
    &-90 { width: 90%; }
    &-100 { width: 100%; }
}

.hide-step {
    display: none;
}

.no-shadow {
    box-shadow: none !important;
}

.text-input-group{
    padding-left: 10px;
    margin-bottom: 0;
    width: 325px;
}

.card-container{
    padding-left: 10px;
    display: flex;
    padding-top: 10px;
    padding-left: 0;
}

.additional-row {
    padding-left: 10px;
}

.warning-link {
    cursor: pointer;
    padding-left: 5px;
}

.expander-button {
    width: 100%;
    height: 40px;
}

.expander-header {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

.text-danger {
    padding-left: 5px;
}

.height-vh {
    &-11 { height:  11.36vh; } 
    &-20 { height:  20vh; } 
    &-33 { height:  33vh; }
    &-35 { height:  35vh; }
}

.max-width {
    &-fit-content { max-width: fit-content; }
    &-20 { max-width: 20px; }
    &-65 { max-width: 66px; }
    &-70 { max-width: 70px; }
    &-72 { max-width: 72px; }
    &-110 { max-width: 110px; }
    &-120 { max-width: 120px; }
    &-130 { max-width: 130px; }
    &-150 { max-width: 150px; }
    &-160 { max-width: 160px; }
    &-180 { max-width: 180px; }
    &-190 { max-width: 190px; }
    &-200 { max-width: 200px; }
    &-220 { max-width: 220px; }
    &-240 { max-width: 240px; }
    &-300 { max-width: 300px; }
    &-340 { max-width: 340px; }
    &-500 { max-width: 500px; }
    &-550 { max-width: 550px; }
    &-600 { max-width: 600px; }
    &-650 { max-width: 650px; }
    &-700 { max-width: 700px; }
    &-800 { max-width: 800px; }
    &-910 { max-width: 910px; }
    &-1100 { max-width: 1100px; }
    @media (min-width: 769px) {
        &-lg-px {
            &-250 { max-width: 250px; }
        }    
    }
     @media (min-width: 769px) {
        &-lg-px {
            &-250 { max-width: 250px; }
        }    
    }
}

.top {
    &-n-1 { top: -1px; }
    &-n-2 { top: -2px; }
    &-3 { top: 3px; }
    &-4 { top: 4px; }
    &-6 { top: 6px; }
    &-9 { top: 9px; }
    &-13 { top: 13px; }
    &-20 { top: 20px; }
    &-30 { top: 30px !important; } //!important Needed to override dropdown element inline style
    &-130 { top: 130px; }
    &-150 { top: 150px; }
    &-200 { top: 200px; }
    &-225 { top: 225px; }

    &-pc {
        &-35 { top: 35%; }
        &-40 { top: 40%; }
        &-42 { top: 42%; }
        &-50 { top: 50%; }
        &-60 { top: 60%; }
        &-70 { top: 70%; }
        &-80 { top: 80%; }
    }
    
    @media (min-width: 375px) {
        &-sm-pc {
            &-25 { top: 25%; }
        }    
    }
    @media (min-width: 768px) {
        &-md-pc {
            &-25 { top: 25%; }
            &-38 { top: 38%; }
        }    
    }
    
}

.bottom {
    &-5 { bottom: 5px; }
    &-14 { bottom: 14px; }
    &-30 { bottom: 30px; }
    &-130 { bottom: 130px; }
}


.left {
    &-n-3 { left: -3px; }
    &-3 { left: 3px; }
    &-8 { left: 8px !important; }       //!important needed to override dynamic styles loaded by DropdownMenu.
    &-18 { left: 18px !important; }
    &-30 { left: 30px !important; }
    &-50 { left: 37px !important; }
    &-130 { left: 130px !important; }

    &-pc {
        &-42 { left: 42%; }
        &-45 { left: 45%; }
        &-47 { left: 47%; }
        &-50 { left: 50%; }
    }
    @media (min-width: 375px) {

        &-sm-pc {
            &-0 { left: 0; }
            &-38 { left: 38%; }
        }    
    }
    @media (min-width: 768px) {
        &-md-pc {
            &-45 { left: 45%; }
        }
    }

}

.loading {
//Override the loading spinner with Wetu green.
    .effect-1, .effect-2, .effect-3 {
        border-left: 3px solid $primary !important;
    }
}

.profile-header-image {
    height: 300px;
    object-fit: cover;
}

.logoImage {
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-side-menu {
    color: $primary !important;
    border-left: solid 3px ;
}

.side-menu {
    border-left:  solid 3px white;
}

//override layout style for custom components
.header-navbar.navbar-static-top {
    left: 0;
    width: 100%;
}

.main-menu {
    margin-left: 2rem;
    border-radius: 0.5rem;
    height: fit-content;

    @media (max-width: 1200px) {
        top: 0px !important;
        margin-left: 0rem;
        border-radius: 0;
    }
}

.add-additional-margin {
    margin-top: 100px;
}

.main-menu.menu-fixed {
   position: absolute;
}

.navbar-container {
   padding-left: 2rem !important;
   padding-right: 2rem !important;
}

.profile-header-nav {
   padding-top: 10px;
}

.p-t-12px {
    padding-top: 12px !important;
}

.font-weight {
    &-400 { font-weight: 400 !important; }
    &-500 { font-weight: 500 !important; }
    &-600 { font-weight: 600 !important; }
    &-700 { font-weight: 700 !important; }
}

.media-list .media {
    padding: 0.25rem;
    margin-top: 0;
}

.right {
    &-0 { right: 0; }
    &-12 { right: 12px; }
    &-45 { right: 45px; }
}

.background {
    &-transparent { background-color: transparent !important; } //!important needed to override _content.scss.
    &-ddd { background-color: #ddd; }
    &-light-primary { background-color: rgba(108, 183, 65, 0.2); }
    &-secondary { background-color: #82868b; }
    &-light-grey { background-color: #F6F7F8 !important; }
    &-unset { background-color:unset !important; } // !important need to override root background color
}

.grey-background {
    background-color: #F3F2F7;
}

.grey-background-color {
    background-color: #F3F2F7 !important;
}

.border1 {
    &-ddd { border: solid 1px #ddd; }
}

.border-bottom1 {
    &-ddd { border-bottom: solid 1px #ddd; }
}

.border-top1 {
    &-ddd { border-top: solid 1px #ddd; }
}

.border-radius-px {
    &-3 { border-radius: 3px; }
    &-5 { border-radius: 5px; }
}

.color {
    &-primary { color: $primary; }
    &-secondary { color: $secondary; }
    &-disabled { color: $disabled; }
    &-danger { color: $danger; }
    &-transparent { color: transparent; }
    &-border { color: $custom-control-border-color; }
}

.color-hover {
    &-primary { &:hover { color: $primary; } }
    &-61a43a { &:hover { color: #61a43a; } }
}

.separator-column {
    padding-top: 23px;
    padding-right: 0px;
    padding-left: 0px;
    min-width: 20px;
    max-width: 20px;
    text-align: center;

    span {
        font-size: 50px;
        line-height: 30px;
    }
}

.overflow {
    &-unset { overflow: unset !important; }     // NB: Overrides the overflow-hidden class in LayoutWrapper
    &-hidden { overflow: hidden; }
}

.overflow-x {
    &-auto { overflow-x: auto;}
}

.overflow-y {
    &-clip { overflow-y: clip;}
    &-visible { overflow-y: visible;}
}

.modal-title {
    font-size: 18px !important;
}

.edit-container{
    padding-left: 2rem;

    .editor-page {
        .card-body {
            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    
    @media (max-width: 1200px) {
        padding-left: 0 !important;
    }
}

@media (max-width: 575.98px) {
    body .app-content.content.overflow-hidden {
        padding: 0 2em 2em 2em !important; 
    }
}

.app-content {
    padding-top: 0 !important; 
}

.popover-bottom-arrow-fix-danger {
    .arrow {
        &:after {
            border-bottom-color: $danger !important;
        }
    }
}

.box-shadow {
    &-none { box-shadow: none;}
}

.z-index {
    &-0 { z-index: 0; }
    &-1 { z-index: 1; }
    &-2 { z-index: 2; }
    &-98 { z-index: 98; }
    &-99 { z-index: 99 !important;}
    &-100 { z-index: 100; }
    &-103 { z-index: 103; }
    &-999 { z-index: 999 !important; }
    &-9999 { z-index: 9999 !important; }
}

.header-navbar {
    z-index: 0
} 

.object-fit {
    &-cover { object-fit: cover; }
}

.custom-checkbox {

    label {
        cursor: pointer;
    }
}

.white-space {
    &-nowrap { white-space: nowrap; }
}

.text-overflow {
    &-ellipsis { text-overflow: ellipsis; }
}

.word-break {
    &-break-all { word-break: break-all; }
}

.flex {
    &-170 { flex: 0 0 170px}
    &-360 { flex: 0 0 360px}
}

.italic {
    font-style: italic;
}

.helper-text {
    color: rgba(34, 41, 47, 0.5) !important;
    font-size: smaller;
}

@media (max-width: 576px) {  
    .navbar-container.content {
        padding-left: 2rem !important; // overrides the navbar-container
        padding-right: 2rem !important; 
    }
}

@media (max-width: 768px) {  
    .main-menu.menu-fixed{
        margin-top: 0;
    }
}
@media (max-width: 375px) {  
    .app-content.content.overflow-unset {
        padding-top: 0 !important; // overrides the calculation in the _content.scss
    }
    
    .accept-decline-modal {
        .modal-content{
            .modal-footer {
                padding: 0.8rem 1.2rem
            }
        }
    }

    .profile-header-image {
        height: 190px;
    }

    .navbar-container.content {
        padding-left: 1.2rem !important; // overrides the navbar-container
        padding-right: 1.2rem !important; 
    }
    .vertical-line {
        border-left: 1px solid;
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (max-width: 1200px) {  
    .main-menu.menu-fixed{
        position: fixed;
        height: 100%;
        overflow: auto;
    }
    .header-navbar .navbar-nav {
        margin-left: auto;
    }
}


.form-control.is-valid {
    background-position: top 0.6rem right 1.2rem !important
}

.removeHover {
    &:hover { color: inherit }
}

.word-count{
    height: 22px;
    font-size: 12px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

.content-overlay {
    cursor: default !important;
}

.header-container {
    background: white;
    box-shadow: 0px 4px 24px 0px #22292F1A;
    align-items: center;
    padding: 10px 10px 10px 20px;
    border-radius: 6px;
    height: 61px;
    flex-wrap: nowrap;
    &>h2 {
        margin: 0;
    }

}

.progress-error {
    background-color: $progress-bg-err;
}
